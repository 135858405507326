<template v-if="this.hasView === true">
  <div class="childpool-wrap">
    <CCard>
      <CCardBody>
        <CRow class="mb-3">
          <CCol lg="12" class="text-right">
            <CButton name="new" class="btn-add-child" @click="handleNew" v-if="hasAdd === true">
              <span>
                <!-- <CIcon class="icon-config mr-1" :content="cilMedicalCross" /> -->
                + New pool
              </span>
            </CButton>
          </CCol>
        </CRow>
        <CRow alignHorizontal="center">
          <CCol lg="12">
            <Tables
              :items="partners"
              :fields="fields"
              :handleEdit="handleEdit"
              :handleConfig="handleConfig"
              hover
              striped
              bordered
              :offset="offset"
            ></Tables>
            <div>
              <CPagination
                v-if="pages>1" 
                :activePage.sync="activePage"
                :pages="pages"
                align="end"
                @update:activePage="pushPage"
              />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Tables from '@/components/ChildPool/Tables';
import { cilMedicalCross } from '@coreui/icons';
import endpoints from '@/constants/endpoints';
import { mapState, mapActions } from 'vuex';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'ListChildPool',
  data() {
    return {
      cilMedicalCross,
      fields: [
        '#',
        {
          key: 'id',
          label: 'Partner ID',
        },
        {
          key: 'name',
          label: 'Partner Name',
        },
        {
          key: 'action',
          label: 'Action',
          noSorting: true,
          noFilter: true,
        },
      ],
      partners: [],
      total: 0,
      limit: 5,
      activePage: 1,
      pages: 0,
      openModal: false,
      hasView: false,
      hasAdd: false,
    };
  },
  components: {
    Tables,
  },
  computed: {
    ...mapState({
       userPermission: state => state.auth.userPermissions,
    }),
    offset() {
      return this.limit * (this.activePage - 1);
    },
  },
  async mounted() {
    const data = await this.loadChildPool();
    if (!data) {
      return;
    }

    this.partners = data.items.slice();
    const totalItems = parseInt(data.total);
    this.pages = Math.ceil(totalItems / this.limit);
    await this.checkPermission();
  },
  methods: {
    ...mapActions(['getSelectedPartner']),
    async loadChildPool() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getGrandchilds, {
          params: params,
        });

        return result.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });

        return null;
      }
    },
    handleNew() {
      this.$router.push('childpool/create');
    },
    handleEdit(item) {
      const partner = this.partners.find(partner => partner.id === item);

      this.getSelectedPartner(partner);
      this.$router.push(`childpool/edit/${item}`);
    },
    /**
     * Handle after use click on config button, save selected partner into the store and push route
     * @param {number} item
     * @return
     */
    handleConfig(item) {
      try {
        // Get selected partner obj
        const partner = this.partners.find(partner => partner.id === item);
        // Dispatch action save to the store
        this.getSelectedPartner(partner);
        this.$router.push(`childpool/detail/${item}`);
        return true;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
        return false;
      }
    },

    async pushPage() {
      const data = await this.loadChildPool();
      if (!data) {
        return;
      }

      this.partners = data.items.slice();
    },
    checkPermission(){
      if (this.userPermission.length > 0) {
         this.userPermission.forEach(items => {
           if (items.name === 'VIEW_LIST_PARTNER') {
             this.hasView = true;
           }
           if (items.name === 'CREATE_PARTNER') {
             this.hasAdd = true;
           }
         });
      }
    }
  },
};
</script>

<style lang="scss">
.childpool-wrap {
  .btn-add-child {
    background: #3b4b7e;
    border: none;
    border-radius: 32px;
    height: 32px;
    padding: 0 30px;
    span {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-align: center;
    }
  }
}
</style>
